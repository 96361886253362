<template>
  <div class="single-page">
    <div class="wrap">
      <div v-if="document.date" class="single-page__date">Редакция от {{document.date}}</div>
<!--      <h1 class="single-page__title">{{ id }}</h1>-->
      <div class="single-page__content" v-html="document.content"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SinglePage",
  computed:{
    ...mapGetters(['storeOferta', 'storePolicy']),
    document(){
      if(this.$route.params.id === 'oferta'){
        return this.storeOferta
      }
      else if(this.$route.params.id === 'policy'){
        return this.storePolicy
      }
      else {
        return ''
      }
    }
  },
  mounted() {
    window.scrollTo(0,0);
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"
.single-page
  padding 40px 0 50px
  @media screen and (min-width 768px)
    padding 50px 0 60px
  @media screen and (min-width 1220px)
    padding 60px 0 70px
  &__date
    border-radius 10px
    background #EBEBEB
    padding 10px 10px 8px
    display inline-block
    margin-bottom 10px
  h1
    font-family Roboto
    font-size 18px
    font-style normal
    font-weight 700
    line-height 130%
    @media screen and (min-width 768px)
      font-size 24px
    @media screen and (min-width 1220px)
      font-size 32px
  &__content
    p
      color $black
      margin 20px 0
    h2
      font-size 16px
      font-style normal
      font-weight 600
      line-height 160%
      color $black
      margin 40px 0 10px
    img
      width auto
      height auto
      max-width 100%
      max-height 100%
      border-radius 30px
      object-fit cover
    .media
      margin 20px 0
      overflow hidden
      img
        width auto
        height auto
        max-width 100%
        max-height 100%
        border-radius 30px
        object-fit cover
      figcaption
        text-align center
        fontPBold()
        color $uiGreyPrimary
        margin-top 5px
      &--align-left
        @media screen and (min-width 768px)
          max-width 50%
          float left
          margin-right 20px
      &--align-right
        @media screen and (min-width 768px)
          max-width 50%
          float right
          margin-left 20px
      &--align-center
        text-align center
        @media screen and (min-width 768px)
          margin-left auto
          margin-right auto
      &--align-full
        width 100%
        height auto
        img
          width 100%

    //h3
    //  fontH3()
    //  margin 30px 0 20px
    //  color $black

    ul
    ol
      margin 20px 0
    li
      list-style none
      margin 10px 0
      //&::marker
      //  position absolute
      //  left 0
      //  top 5px
      &:first-child
        margin-top 0
      &:last-child
        margin-bottom 0

    //.quote
    //  background-color $uiWhite
    //  border-radius 30px
    //  padding 25px
    //  margin-top 20px
    //  fontDefault()
    //  color $uiBlackSecondary
    //
    //  span
    //    fontPBold()
    //    display block
    //    color $uiBlackSecondary
    //    text-align right
    //    margin-top 10px
    //
    //hr
    //  background-color $uiGreySecondary
    //  margin 20px 0
    //
    //table
    //  fontDefault()
    //  margin 20px 0
    //  overflow-x auto
    //  border-collapse collapse
    //  th
    //    fontPBold()
    //    background-color $uiBlueGrey
    //  th,
    //  td
    //    border 1px solid $uiGreySecondary
    //    padding 10px
    //
    //blockquote
    //  margin 20px 0
    //  border-left 1px solid $uiGreySecondary
    //  padding-left 20px
</style>