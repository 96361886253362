<template>
<footer class="footer">
  <div class="wrap">
    <div class="footer__body">
      <LogoBase class="footer__logo" :is-primary="false"/>
      <div class="footer__tel">
        <div class="footer__tel__number"><TelPrimary/></div>
        <div class="footer__tel__caption">Для звонков по России</div>
      </div>
      <to-order v-if="!isMobile"><button-text-base style="font-size: 15px">Заказать грузоперевозку</button-text-base></to-order>
    </div>
    <div class="footer__contact">
      <ContactList class="footer__contact__list"/>
      <nav class="footer__docs">
        <div class="footer__docs__item footer__docs__item--link"><to-policy>Политика</to-policy></div>
        <div class="footer__docs__item footer__docs__item--link"><to-oferta>Оферта</to-oferta></div>
        <div class="footer__docs__item">{{ copyright }}</div>
      </nav>
    </div>
  </div>
</footer>
</template>

<script>
import LogoBase from "@/shared/ui/LogoBase";
import TelPrimary from "@/features/TelPrimary";
import ToOrder from "@/features/ToOrder";
import ButtonTextBase from "@/shared/ui/ButtonTextBase";
import ContactList from "@/widgets/ContactList";
import ToPolicy from "@/features/ToPolicy";
import {mapGetters} from "vuex";
import ToOferta from "@/features/ToOferta";
export default {
  name: "TheFooter",
  components: {ToOferta, ToPolicy, ContactList, ButtonTextBase, ToOrder, TelPrimary, LogoBase},
  computed:{
    ...mapGetters(['getWindowSize']),
    copyright(){
      return `© ООО "ТИЗА Логистик", ${new Date().getFullYear()}`;
    },
    isMobile(){
      return this.getWindowSize?.isMobile
    },
  },
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"

.footer
  background-color $greyDark
  color #fff
  &__body
    padding-top 25px
    padding-bottom 20px
    border-bottom 1px solid #909090
    display flex
    justify-content space-between
    align-items center
    gap 30px
  &__contact
    padding-top 20px
    padding-bottom 30px
    display flex
    justify-content space-between
    align-items center
    flex-wrap wrap
    @media screen and (max-width 1220px)
      align-items start
      gap: 20px
    &__list
      display flex
      gap 10px
      @media screen and (max-width 1220px)
        display flex
        flex-direction column
        gap: 0
        > .contact-list__tel
          flex-direction column
          gap: 0
          > .item
            justify-content unset

      > .contact-list__address > .item__content
        max-width unset

      > .contact-list__tel
        > .item > .item__content > div > .link-base
          color: unset
          font-size 14px
        > .contact-list__email > .item__content > .link-base
          color: unset
  &__logo
    margin-top -20px
  &__tel
    text-align right
    margin-left auto
    &__number
      font-size 20px
      font-style normal
      font-weight 600
      margin-bottom 5px
    &__caption
      font-style 13px
  &__docs
    display flex
    justify-content flex-start
    align-items center
    gap 20px
    &__item
      font-size 12px
      color $greyLight
      &--link
        text-decoration underline
</style>