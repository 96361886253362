<template>
<!--  <div>-->
    <form-element class="order-create__input">
      <FormInput
          type="text"
          :has-background-grey="true"
          label="Город отправления"
          :has-error="false"
          ref="departure"
          @onInput="onInputDeparture"
      />
      <template #caption>
        <form-caption v-if="storeGetDeparture('note')">
          <caption-error>{{storeGetDeparture('note')}}</caption-error>
        </form-caption>
      </template>
    </form-element>
    <form-element class="order-create__input">
      <FormInput
          type="text"
          :has-background-grey="true"
          label="Город получения"
          ref="destination"
          :has-error="false"
          @onInput="onInputDestination"
      />
      <template #caption>
        <form-caption v-if="storeGetDestination('note')">
          <caption-error>{{storeGetDestination('note')}}</caption-error>
        </form-caption>
      </template>
    </form-element>
    <form-element class="order-create__input">
      <FormInput
          :is-float="true"
          type="text"
          :has-background-grey="true"
          label="Общий вес, кг"
          :has-error="false"
          ref="weight"
          @onInput="onInputWeight"
      />
      <template #caption>
        <form-caption v-if="storeGetWeight('note')">
          <caption-error>{{storeGetWeight('note')}}</caption-error>
        </form-caption>
      </template>
    </form-element>
    <form-element class="order-create__input">
      <FormInput
          :is-float="true"
          type="text"
          :has-background-grey="true"
          label="Общий объем, м3"
          ref="volume"
          :has-error="false"
          @onInput="onInputVolume"
      />
      <template #caption>
        <form-caption v-if="storeGetVolume('note')">
          <caption-error>{{storeGetVolume('note')}}</caption-error>
        </form-caption>
      </template>
    </form-element>
    <form-element class="order-create__input">
      <FormInput
          :is-integer="true"
          type="text"
          :has-background-grey="true"
          label="Количество мест , шт"
          :has-error="false"
          ref="place"
          @onInput="onInputPlace"
      />
      <template #caption>
        <form-caption v-if="storeGetPlace('note')">
          <caption-error>{{storeGetPlace('note')}}</caption-error>
        </form-caption>
      </template>
    </form-element>
<!--  </div>-->
</template>

<script>
import FormElement from "@/shared/ui/FormElement";
import FormInput from "@/shared/ui/FormInput";
import FormCaption from "@/shared/ui/FormCaption";
import CaptionError from "@/shared/ui/CaptionError";
import {mapGetters, mapMutations} from "vuex";
export default {
  name: "FormCargo",
  components: {CaptionError, FormCaption, FormInput, FormElement},
  computed:{
    ...mapGetters(['storeGetDeparture', 'storeGetDestination', 'storeGetWeight', 'storeGetVolume', 'storeGetPlace']),
  },
  data(){
    return{
      volume: null
    }
  },
  methods:{
    ...mapMutations(['storeUpdateDeparture', 'storeUpdateDestination', 'storeUpdateWeight', 'storeUpdateVolume', 'storeUpdatePlace']),
    onInputDeparture: function (value){
      this.storeUpdateDeparture({value, note: null})
    },
    onInputDestination: function (value){
      this.storeUpdateDestination({value, note: null})
    },
    onInputWeight: function (value){
      this.storeUpdateWeight({value, note: null})
    },
    onInputVolume: function (value){
      this.storeUpdateVolume({value, note: null})
    },
    onInputPlace: function (value){
      this.storeUpdatePlace({value, note: null})
    },

    toReset: function (){
        this.$refs.departure.toResetInput()
        this.$refs.destination.toResetInput()
        this.$refs.weight.toResetInput()
        this.$refs.volume.toResetInput()
        this.$refs.place.toResetInput()
    },
  }
}
</script>

<style lang="stylus">
.order-create__input
  width: 100%;
  max-width unset !important
</style>