<template>
  <header class="header" id="header">
    <div ref="preline" v-show="isTablet" class="header__preline wrap">
      <ContactList class="header__contact-list"/>
    </div>
    <div ref="body">
      <div class="header__body">
        <div class="wrap">
          <RouterLink :to="{name: 'home'}">
            <LogoBase
              class="header__body__logo"
              :is-primary="true"
            />
          </RouterLink>

          <div class="header__tel">
            <ContactList
                v-show="isDesktop"
                class="header__contact-list"
            />

            <to-order
                v-show="isTablet || isDesktop"
                class="header__order"
            >
              <button-text-base style="font-size: 15px">Заказать грузоперевозку</button-text-base>
            </to-order>
          </div>

          <div
            v-if="!isDesktop"
            class="header__menu__button"
            @click="isActiveMenu = !isActiveMenu"
          >
            <svg v-if="!isActiveMenu" xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none"><path fill="#A6C23A" d="M33.417 18H6.583C5.71 18 5 18.638 5 19.425v.15C5 20.362 5.709 21 6.583 21h26.834C34.29 21 35 20.362 35 19.575v-.15c0-.787-.709-1.425-1.583-1.425ZM33.417 27H6.583C5.71 27 5 27.638 5 28.425v.15C5 29.362 5.709 30 6.583 30h26.834C34.29 30 35 29.362 35 28.575v-.15c0-.787-.709-1.425-1.583-1.425ZM33.417 10H6.583C5.71 10 5 10.638 5 11.425v.15C5 12.362 5.709 13 6.583 13h26.834C34.29 13 35 12.362 35 11.575v-.15c0-.787-.709-1.425-1.583-1.425Z"/></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none"><path fill="#A6C23A" d="M8.999 28.094 27.973 9.12c.618-.619 1.57-.669 2.127-.112l.106.106c.556.556.506 1.509-.112 2.127L11.12 30.215c-.618.618-1.57.668-2.127.112l-.106-.106c-.557-.557-.507-1.51.112-2.127Z"/><path fill="#A6C23A" d="M30.094 28.094 11.12 9.12C10.5 8.5 9.549 8.45 8.992 9.008l-.106.106c-.556.556-.506 1.509.112 2.127l18.974 18.974c.619.618 1.571.668 2.127.112l.107-.106c.556-.557.506-1.51-.112-2.127Z"/></svg>
          </div>
        </div>
      </div>

      <MenuList v-show="isDesktop" class="header__menu-list wrap"/>
    </div>

    <teleport to="body">
      <div v-if="isActiveMenu">
        <div ref="modal" class="header__menu-modal" :style="`height: ${modalHeight()}px; top:${headerHeight()}px`">
          <div v-show="!isDesktop">
            <MenuList
                @clicked="isActiveMenu = false"
                class="header__menu-modal__menu-list wrap"
            />

            <to-order
                v-show="isMobile"
                class="header__order"
            >
              <button-text-base style="font-size: 15px">Заказать грузоперевозку</button-text-base>
            </to-order>
          </div>

          <ContactList
            v-show="isMobile"
            class="header__contact-list header__menu-modal__contact-list"
          />
        </div>
      </div>

    </teleport>
  </header>
</template>

<script>
import ButtonTextBase from "@/shared/ui/ButtonTextBase";
import ToOrder from "@/features/ToOrder";
import LogoBase from "@/shared/ui/LogoBase";
import ContactList from "@/widgets/ContactList";
import {mapGetters} from "vuex";
import MenuList from "@/widgets/MenuList";

export default {
  name: "TheHeader",
  components: {
    MenuList,
    ButtonTextBase,
    ToOrder,
    LogoBase,
    ContactList
  },
  data(){
    return{
      isActiveMenu: false,
      height: 0
    }
  },
  computed:{
    ...mapGetters(['getWindowSize']),
    isDesktop(){
      return this.getWindowSize?.isDesktop
    },
    isTablet(){
      return this.getWindowSize?.isTablet
    },
    isMobile(){
      return this.getWindowSize?.isMobile
    },
  },
  mounted() {
    setTimeout(()=>{
      this.visibilityHeader()
    }, 1)
  },
  beforeUnmount() {
    const body = document.getElementById('app')
    body.style.paddingTop = `0px`
  },
  methods:{
    // Показать/скрыть header при скролле
    visibilityHeader: function (){

        let elY = 0;
        let scrollY = 0;
        const header = document.querySelector('#header');
        const body = document.getElementById('app')
        let height = this.height = header.offsetHeight;
        body.style.paddingTop = `${height}px`
        window.addEventListener("resize", () => {
          height = this.height = header.offsetHeight;
          body.style.paddingTop = `${height}px`
        })

        window.addEventListener("scroll", () => {
          const pos = window.pageYOffset;
          const diff = scrollY - pos;

          elY = Math.min(0, Math.max(-height, elY + diff));
          header.style.position = pos >= height || this.isActiveMenu ? 'fixed' : pos === 0 && !this.isActiveMenu ? 'absolute' : header.style.position;
          header.style.top = `${header.style.position === 'fixed' && !this.isActiveMenu ? elY : 0}px`;
          scrollY = pos;
        })

    },

    modalHeight(){
      return window.innerHeight - this.headerHeight()
    },
    headerHeight(){
      return this.$refs.preline.clientHeight + this.$refs.body.clientHeight
    }
  }
}
</script>

<style lang="stylus">
@import "@/shared/styl/variables.styl"

.header
  width 100%
  z-index 3
  position absolute
  top 0
  left 0
  background-color $white
  box-shadow 0 4px 10px 0 rgba(0, 0, 0, 0.03)
  &__body
      padding-top 17px
      padding-bottom 15px
      > .wrap
        display flex
        align-items center
        gap: 30px
      &__logo
        margin-top -17px
      @media screen and (min-width 1220px)
        > .wrap
          padding-left 0

  &__contact-list
    color $greyDark
    display flex
    justify-content space-around
    align-items center
    width: 100%
    > *:first-child
      width 100%
      text-align center
      align-items start
      justify-content center
    @media screen and (min-width 768px)
      flex-wrap nowrap
      > *:first-child
        justify-content flex-start
        width auto
        margin-right auto
    > .contact-list__tel
      flex: 1
  &__order
    min-width 240px
    > button
      width 100%
  &__preline
    padding-top 10px
    padding-bottom 14px
    border-bottom 1px solid $greyLight
    margin-bottom 5px
  &__menu-list
    border-top 1px solid $greyLight
    padding-top 15px
    padding-bottom 15px
    display flex
    justify-content space-between
    align-items center
  &__menu-modal
    height 100vh
    position fixed
    display flex
    flex-direction column
    justify-content space-between
    align-items center
    gap 30px
    width 100%
    left 0
    background-color $white
    padding-top 60px
    padding-left 20px
    padding-right 20px

    @media screen and (min-width 768px)
      padding-top 250px

    > *:first-child
      display flex
      flex-direction column
      align-items center
      gap 60px

    &__contact-list
      display flex
      flex-direction column
      padding-bottom 40px
      text-align center
      gap: 20px
      > .contact-list__address > .item__content
        max-width unset
      > .contact-list__tel
        display flex
        flex-direction column
        gap 5px
        align-items center

  &__tel
    position relative
    display flex
    justify-content space-between
    width 100%
    gap: 30px
    @media screen and (max-width 1220px)
      justify-content end
</style>